import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import moment from 'moment';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit } from 'firebase/firestore'

export default function ViewPurchase() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [userData, setUserData] = useState();
  const [searchText, setsearchText] = useState("");
  const [purchases, setpurchases] = useState([]);
  const [purchaseItesms, setpurchaseItesms] = useState([]);

  useEffect(() => {
    if (searchText === '') {
      fetchData1()
    } else {
      fetchData2()
    }
  }, [searchText]);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {
      // Fetch all purchases at once
      const q1 = query(collection(db, 'purchases'), orderBy("created", "desc"), where("status", "==", "Active"));
      const querySnapshot1 = await getDocs(q1);

      // Collect all dealerIds
      const dealerIds = querySnapshot1.docs.map((doc) => doc.data().dealerId);
      const uniqueDealerIds = [...new Set(dealerIds)]; // Remove duplicates

      // Batch fetch all dealer data at once
      const dealerDocs = await Promise.all(
        uniqueDealerIds.map((dealerId) => getDoc(doc(db, "dealers", dealerId)))
      );

      // Create a map for quick dealer lookup
      const dealerMap = dealerDocs.reduce((map, dealerDoc) => {
        if (dealerDoc.exists()) {
          map[dealerDoc.id] = dealerDoc.data().name;
        }
        return map;
      }, {});

      // Combine purchase data with dealer data
      const combinedData = querySnapshot1.docs.map((doc1) => ({
        id: doc1.id,
        data: doc1.data(),
        dealername: dealerMap[doc1.data().dealerId] || "", // Use dealerMap for fast lookup
      }));

      setpurchases(combinedData);
    } catch (error) {
      console.log(error.message);
      Toast.fire({
        icon: "error",
        title: error.message,
      });
    }
  };

  const fetchData2 = async () => {
    try {
      if (purchases) {
        const filteredData = purchases.filter((item) =>
          item.dealername.toUpperCase().includes(searchText.toUpperCase())
        );
        setpurchases(filteredData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }




  const handleDelete = async (docid) => {
    const taskDocRef = doc(db, 'purchases', docid)
    await deleteDoc(taskDocRef)

    const q11 = query(collection(db, 'purchasesitems'), where("purchaseid", "==", docid));
    onSnapshot(q11, (querySnapshot3) => {
      querySnapshot3.docs.map(async (item) => {
        console.log(item.id);
        const taskDocRef1 = doc(db, 'purchasesitems', item.id)
        await deleteDoc(taskDocRef1)
      }
      )
    });

    const q12 = query(collection(db, 'dealeracchistory'), where("purchasedocid", "==", docid));
    onSnapshot(q12, (querySnapshot3) => {
      querySnapshot3.docs.map(async (item) => {
        const taskDocRef2 = doc(db, 'dealeracchistory', item.id)
        await deleteDoc(taskDocRef2)
      }
      )
    });

    Toast.fire({ icon: "success", title: 'Purchase Deleted Successfully!!' });
    fetchData1();
  }

  const handleView = (docid) => {
    const q = query(collection(db, 'purchasesitems'), where("purchaseid", "==", docid));
    onSnapshot(q, (querySnapshot2) => {
      setpurchaseItesms(querySnapshot2.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })));
    });
  }




  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right" }} >|| View Purchase ||</button>
            <a className="btn btn-info btn-sm" href="add-purchase">Add Purchase</a><br /><br />
            <div className="card card-primary card-outline ex1">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >
                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Purchase Date</th>
                      <th>Purchase No</th>
                      <th>Dealer Name</th>
                      <th style={{textAlign:"right"}}>TCS (1%) </th>
                      <th style={{textAlign:"right"}}>Handling Charges </th>
                      <th style={{textAlign:"right"}}>Discount </th>
                      <th style={{textAlign:"right"}}>Grand Total </th>
                      <th style={{textAlign:"right"}}>Paid Amount</th>
                      <th style={{textAlign:"right"}}>Remaining</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      purchases.map((item, num = 1) => {
                        return (
                          <tr key={num + 1}>
                            <td width="80">{num + 1}</td>
                            <td>{moment(item.data.purchaseDate).format('DD-MM-YYYY')}</td>
                            <td>{item.data.purchaseNo}</td>
                            <td>{item.dealername}</td>
                            <td style={{textAlign:"right"}}>{parseFloat(item.data.tcs).toFixed(2)}</td>
                            <td style={{textAlign:"right"}}>{parseFloat(item.data.handlingcharge).toFixed(2)}</td>
                            <td style={{textAlign:"right"}}>{parseFloat(item.data.totdiscount).toFixed(2)}</td>
                            <td style={{textAlign:"right"}}>{parseFloat(item.data.grandtotal).toFixed(0)}</td>
                            <td style={{textAlign:"right"}}>{parseFloat(item.data.paidamount).toFixed(0)}</td>
                            <td style={{textAlign:"right"}}>{parseFloat(item.data.remaining).toFixed(0)}</td>
                            <td>
                              <a className="btn btn-sm btn-info" href={'edit-purchase/' + item.id}> <i className="fa fa-edit"></i></a>&nbsp;
                              <a onClick={() => handleView(item.id)} className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal1"> <i className="fa fa-eye"></i></a>&nbsp;
                              <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                            </td>
                          </tr>
                        )
                      }


                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="modal fade" id="text-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">View Purchase Items</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <table className='customers' style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th><label>Item</label></th>
                    <th><label>Rate</label></th>
                    <th><label>Qty</label></th>
                    <th><label>Discount</label></th>
                    <th><label>Total </label></th>
                    {/* <th><label>Gst  </label></th> */}
                    {/* <th><label>Gst Amnt  </label></th> */}
                    {/* <th><label>All Total</label></th> */}
                  </tr>
                </thead>
                <tbody>

                  {
                    purchaseItesms.map((item, index) =>
                      <tr key={index}>
                        <td>{item.data.itemname}</td>
                        <td>{item.data.rate}</td>
                        <td>{item.data.qty}</td>
                        <td>{item.data.discount}</td>
                        <td>{item.data.subtotal.toFixed(2)}</td>
                        {/* <td>{item.data.gst}</td> */}
                        {/* <td>{item.data.gst_amount}</td> */}
                        {/* <td>{item.data.total_amount.toFixed(2)}</td> */}
                      </tr>

                    )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}
