import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, query, orderBy, onSnapshot, getDoc, doc, where, getAggregateFromServer, sum, } from 'firebase/firestore'
import moment from 'moment';
import Select from 'react-select'
import { useReactToPrint } from 'react-to-print';


export default function Productwise() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setfromdate(formattedDate);
    }, []);

    const printRef = useRef();
    const [reportData, setreportData] = useState([]);
    const [fromdate, setfromdate] = useState("");
    const [totalamount, settotalamount] = useState("");

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })



    const getReport = () => {
        settotalamount(0);
        setreportData([]);
      
        const q1 = query(collection(db, 'productwisesale'), where('orderdate', '==', fromdate));
      
        onSnapshot(q1, async (querySnapshot1) => {
          const combinedData = [];
          let total_amount = 0;
      
          // Iterate through the query snapshot and calculate total_amount
          for (const doc1 of querySnapshot1.docs) {
            total_amount += parseFloat(doc1.data().rate) * parseFloat(doc1.data().quantity);
            combinedData.push({
              id: doc1.id,
              data: doc1.data(),
            });
          }
      
          // Sort combinedData by itemname in ascending order
          combinedData.sort((a, b) => a.data.itemname.localeCompare(b.data.itemname));
      
          // Set state with sorted data and total amount
          settotalamount(total_amount);
          setreportData(combinedData);
        });
      };
      
    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary" style={{ float: "right" }} >|| Product Wise Report  ||</button><br /><br />
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className="col-sm-3">
                                        <label> Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <br />
                                        <button onClick={() => getReport()} className='btn btn-info'>Get Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" ref={printRef}>
                                <center>
                                    <h6>Pratik Beer Shopee</h6>
                                    {/* <span>Ghatshil Road Road, Tuljapur, 413501</span><br /> */}
                                    {/* <span>Mobile No: 9921060146</span> */}
                                </center><hr />
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <span><b> Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                    <div className='col-sm-4 text-center'>
                                        <h6 style={{ textTransform: "uppercase" }}>Product Wise Report</h6>
                                    </div>
                                    <div className='col-sm-4'>
                                    </div>
                                </div>
                                <div className='row '>
                                    <table className='table table-striped'>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Date</th>
                                            <th>Prodcut Name</th>
                                            <th>Rate</th>
                                            <th>Quantity</th>
                                            <th>Total</th>

                                        </tr>
                                        <tr>
                                            <td colSpan={4}><b>Total</b></td>
                                            <td><b></b></td>
                                            <td><b>{totalamount}</b></td>
                                        </tr>
                                        {
                                            reportData.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    <td>{moment(fromdate).format('DD-MM-YYYY')}</td>
                                                    <td>{item.data.itemname}</td>
                                                    <td>{item.data.rate}</td>
                                                    <td>{item.data.quantity}</td>
                                                    <td>{item.data.rate * item.data.quantity}</td>

                                                </tr>
                                            )}

                                    </table>
                                </div>
                            </div>
                            <center>
                                <button onClick={handlePrint} className='btn btn-info'>Print Report</button>
                            </center><br />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
