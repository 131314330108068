import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, Firestore, getAggregateFromServer, sum, updateDoc } from 'firebase/firestore'
import Select from 'react-select'
import { useParams } from 'react-router-dom';

let purchaseid;

export default function Editpurchase() {

    const param = useParams();
    purchaseid = param.id;

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });


    useEffect(() => {
        fetchProducts();
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setpurchaseDate(formattedDate);
        fetchPurchaseItems();
    }, []);

    const [productunit, setproductunit] = useState("");
    const [stock, setstock] = useState(0);
    const [prodcatid, setprodcatid] = useState("");
    const [delaeraccid, setdelaeraccid] = useState("");
    const [prodname, setprodname] = useState("");

    const [productCat, setproductCat] = useState([]);
    const [productUnitData, setproductUnitData] = useState([]);
    const [products, setproducts] = useState([]);
    const [dealers, setdealers] = useState([]);
    const [purchaseItesms, setpurchaseItesms] = useState([]);

    const [name, setname] = useState("");
    const [mobile, setmobile] = useState("");
    const [address, setaddress] = useState("");

    const [itemid, setitemid] = useState("");
    const [item, setitem] = useState("");
    const [barcode, setbarcode] = useState("");
    const [qty, setqty] = useState(1);
    const [rate, setrate] = useState("");
    const [subtotal, setsubtotal] = useState("");
    const [discount, setdiscount] = useState(0);
    const [gst, setgst] = useState(0);
    const [gst_amount, setgst_amount] = useState("");
    const [total_amount, settotal_amount] = useState("");

    const [upitemid, setupitemid] = useState("");
    const [upbarcode, setupbarcode] = useState("");
    const [upqty, setupqty] = useState(1);
    const [uprate, setuprate] = useState("");
    const [upsubtotal, setupsubtotal] = useState("");
    const [updiscount, setupdiscount] = useState(0);
    const [upgst, setupgst] = useState(0);
    const [upgst_amount, setupgst_amount] = useState("");
    const [uptotal_amount, setuptotal_amount] = useState("");


    const [handlingcharge, sethandlingcharge] = useState(0);
    const [totdiscount, settotdiscount] = useState(0);
    const [tcs, settcs] = useState(0);
    const [grandtotal, setgrandtotal] = useState(0);
    const [grandtotal1, setgrandtotal1] = useState(0);
    const [paidamount, setpaidamount] = useState(0);
    const [remaining, setremaining] = useState(0);

    const [dealerId, setdealerId] = useState("");
    const [purchaseNo, setpurchaseNo] = useState("");
    const [purchaseDate, setpurchaseDate] = useState("");
    const [paytype, setpaytype] = useState("Cash");

    // ::::::::::::::::::::::::::::::::::::::::::::::::::: GET DEALERS DATA FUNCTION STATR ::::::::::::::::::::::::::::::::::::::

    const fetchProducts = async () => {
        try {

            const docRef = doc(db, "purchases", purchaseid) // db = getFirestore()
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                setdealerId(docSnap.data().dealerId)
                setpurchaseNo(docSnap.data().purchaseNo)
                setpurchaseDate(docSnap.data().purchaseDate)
                sethandlingcharge(docSnap.data().handlingcharge)
                settotdiscount(docSnap.data().totdiscount)
            }

            const q1221 = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseid));
            const snapshot = await getAggregateFromServer(q1221, {
                totalAmount: sum('total_amount')
            });
            let tcs = (snapshot.data().totalAmount * 1) / 100;
            let rgrandtotal = parseFloat(snapshot.data().totalAmount) + parseFloat(tcs) + parseFloat(docSnap.data().handlingcharge) - parseFloat(docSnap.data().totdiscount);
            let rgrandtotal1 = parseFloat(snapshot.data().totalAmount) + parseFloat(tcs);

            settcs(tcs.toFixed(2));
            setgrandtotal(rgrandtotal.toFixed(0));
            setgrandtotal1(rgrandtotal1.toFixed(0));
            let gtotal = rgrandtotal;
            let remainingnew = parseFloat(gtotal) - parseFloat(paidamount);
            if (remainingnew < 0) {
                Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
                setpaidamount("");
                setremaining(gtotal.toFixed(0));
                return;
            }
            if (paidamount === '') {
                setremaining(gtotal.toFixed(0));
            } else {
                setremaining(remainingnew.toFixed(0));
            }

            const qdear = query(collection(db, 'dealeracchistory'), where("purchasedocid", "==", purchaseid));
            onSnapshot(qdear, (querySnapshot21) => {
                setdelaeraccid(querySnapshot21.docs[0].id);
            });

            const q = query(collection(db, 'productcats'))
            onSnapshot(q, (querySnapshot) => {
                setproductCat(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q1 = query(collection(db, 'products'), orderBy("prodname", "desc"))
            onSnapshot(q1, async (querySnapshot1) => {

                const combinedData = [];
                let catname = "";
                let unit = "";
                for (const doc1 of querySnapshot1.docs) {

                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        catname: catname,
                        unit: unit,
                        label: doc1.data().prodname + " " + unit,
                        value: doc1.id,
                    });
                }
                setproducts(combinedData);
            });

            const q2 = query(collection(db, 'productunits'), orderBy("created", "asc"))
            onSnapshot(q2, (querySnapshot2) => {
                setproductUnitData(querySnapshot2.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data(),
                    isChecked: true
                })));
            });

            const q3 = query(collection(db, 'dealers'))
            onSnapshot(q3, (querySnapshot3) => {
                setdealers(querySnapshot3.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });

            const q122 = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseid));
            onSnapshot(q122, (querySnapshot2) => {
                setpurchaseItesms(querySnapshot2.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data(),
                })));
            });
        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }


    const productInsert = async () => {
        if (!prodname || !prodcatid) {
            Toast.fire({
                icon: "error",
                title: 'Please fill all the fields!!'
            });
            return;
        }

        await addDoc(collection(db, 'products'), {
            prodcatid: prodcatid,
            prodname: prodname,
            unit: productunit,
            stock: stock,
            uid: userData.uid,
            created: Timestamp.now()
        });


        setprodcatid("");
        setprodname("");
        Toast.fire({
            icon: "success",
            title: 'Product Added Successfully!!'
        });
    }

    const dealerInsert = async () => {
        await addDoc(collection(db, 'dealers'), {
            name: name,
            mobile: mobile,
            address: address,
            uid: userData.uid,
            created: Timestamp.now()
        });
        setname(""); setmobile(""); setaddress("");
        Toast.fire({
            icon: "success",
            title: 'Dealer Added Successfully!!'
        });
    }

    //  ####################################################### Calculation code ##########################################

    const handleSelectItem = (item) => {
        setitem(item);
        setitemid(item.id);
        caltotal();
    };

    const caltotal = () => {
        let subtotal = parseFloat(qty) * parseFloat(rate) - parseFloat(discount);
        let gstamount = (subtotal * parseFloat(gst)) / 100;
        let total_amount = parseFloat(subtotal) + parseFloat(gstamount);
        setsubtotal(subtotal.toFixed(2));
        setgst_amount(gstamount.toFixed(2));
        settotal_amount(total_amount.toFixed(2))
    }

    const caltotal1 = () => {
        let subtotalsdsd = parseFloat(upqty) * parseFloat(uprate) - parseFloat(updiscount);
        let gstamountsdds = (subtotalsdsd * parseFloat(upgst)) / 100;
        setupsubtotal(subtotalsdsd.toFixed(2));
        setupgst_amount(gstamountsdds.toFixed(2));
        setuptotal_amount(subtotalsdsd.toFixed(2))
    }


    const finaltotal = () => {
        let rgrandto = parseFloat(grandtotal1) + parseFloat(handlingcharge)- parseFloat(totdiscount);
        setgrandtotal(rgrandto.toFixed(0));
        let remainingnew = parseFloat(rgrandto) - parseFloat(paidamount);
        if (remainingnew < 0) {
            Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
            setpaidamount("");
            setremaining(grandtotal.toFixed(0));
            return;
        }
        if (paidamount === '') {
            setremaining(grandtotal.toFixed(0));
        } else {
            setremaining(remainingnew.toFixed(0));
        }
    }

    //  ####################################################### Purchase Codes ##########################################

    const fetchPurchaseItems = async (purchaseid) => {
        if (!purchaseid) {
            setpurchaseItesms([]);
            return;
        }
        try {


            const q = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseid));
            onSnapshot(q, (querySnapshot2) => {
                setpurchaseItesms(querySnapshot2.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data(),
                })));
            });

            const q1 = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseid));
            const snapshot = await getAggregateFromServer(q1, {
                totalAmount: sum('total_amount')
            });
            let tcs = (snapshot.data().totalAmount * 1) / 100;
            let rgrandtotal = parseFloat(snapshot.data().totalAmount) + parseFloat(tcs) + parseFloat(handlingcharge) - parseFloat(totdiscount);
            let rgrandtotal1 = parseFloat(snapshot.data().totalAmount) + parseFloat(tcs);

            settcs(tcs);
            setgrandtotal(rgrandtotal);
            setgrandtotal1(rgrandtotal1);
            let gtotal = rgrandtotal;
            let remainingnew = parseFloat(gtotal) - parseFloat(paidamount);
            if (remainingnew < 0) {
                Toast.fire({ icon: "error", title: 'Remaining should not be less than 0' });
                setpaidamount("");
                setremaining(gtotal);
                return;
            }
            if (paidamount === '') {
                setremaining(gtotal);
            } else {
                setremaining(remainingnew);
            }


        } catch (error) {
            console.log(error.message);
        }

    }

    const addPurchaseItems = async () => {
        if (!dealerId) {
            Toast.fire({ icon: "error", title: 'Please Select Dealer' });
            return;
        }
        if (!purchaseDate) {
            Toast.fire({ icon: "error", title: 'Please Select Purchase Date' });
            return;
        }

        if (!itemid || !rate || !qty || !barcode) {
            Toast.fire({ icon: "error", title: 'Product, Rate, Quantity, Barcode should not be empty' });
            return;
        }
        if (!total_amount) {
            Toast.fire({ icon: "error", title: 'Please check the Total Amount' });
            return;
        }

        if (!purchaseid) {
            const docRef = await addDoc(collection(db, 'purchases'), {
                dealerId: dealerId,
                purchaseNo: purchaseNo,
                purchaseDate: purchaseDate,
                tcs: parseFloat(tcs),
                grandtotal: parseFloat(grandtotal),
                paidamount: parseFloat(paidamount),
                remaining: parseFloat(remaining),
                paytype: paytype,
                status: 'Pending',
                uid: userData.uid,
                created: Timestamp.now()
            });
            await addDoc(collection(db, 'purchasesitems'), {
                dealerId: dealerId,
                purchaseid: docRef.id,
                purchaseDate: purchaseDate,
                itemname: item.data.prodname + " " + item.unit,
                itemid: itemid,
                unitname: item.unit,
                qty: parseFloat(qty),
                rate: parseFloat(rate),
                barcode: barcode,
                subtotal: parseFloat(subtotal),
                discount: parseFloat(discount),
                gst: parseFloat(gst),
                gst_amount: parseFloat(gst_amount),
                total_amount: parseFloat(total_amount),
                status: 'Pending',
                uid: userData.uid,
                created: Timestamp.now()
            });

            setitemid(""); setitem(""); setqty(1); setrate(""); setsubtotal(""); setdiscount(0); setgst(0);
            setgst_amount(""); settotal_amount(""); setbarcode("")

            fetchPurchaseItems(docRef.id);

        } else {
            await setDoc(doc(db, 'purchases', purchaseid), {
                dealerId: dealerId,
                purchaseNo: purchaseNo,
                purchaseDate: purchaseDate,
                tcs: parseFloat(tcs),
                grandtotal: parseFloat(grandtotal),
                paidamount: parseFloat(paidamount),
                remaining: parseFloat(remaining),
                paytype: paytype,
                status: 'Pending',
                uid: userData.uid,
                created: Timestamp.now()
            });
            await addDoc(collection(db, 'purchasesitems'), {
                dealerId: dealerId,
                purchaseDate: purchaseDate,
                purchaseid: purchaseid,
                itemname: item.data.prodname + " " + item.unit,
                itemid: itemid,
                unitname: item.unit,
                qty: parseFloat(qty),
                rate: parseFloat(rate),
                barcode: barcode,
                subtotal: parseFloat(subtotal),
                discount: parseFloat(discount),
                gst: parseFloat(gst),
                gst_amount: parseFloat(gst_amount),
                total_amount: parseFloat(total_amount),
                status: 'Pending',
                uid: userData.uid,
                created: Timestamp.now()
            });

            fetchPurchaseItems(purchaseid);

            setitemid(""); setitem(""); setqty(1); setrate(""); setsubtotal(""); setdiscount(0); setgst(0);
            setgst_amount(""); settotal_amount(""); setbarcode("")


        }

        await addDoc(collection(db, 'barcodes'), {
            prodiid: itemid,
            barcode: barcode,
            uid: userData.uid,
            created: Timestamp.now()
        });


    }

    const updatePurchaseItem = async () => {
        if (!dealerId) {
            Toast.fire({ icon: "error", title: 'Please Select Dealer' });
            return;
        }
        if (!purchaseDate) {
            Toast.fire({ icon: "error", title: 'Please Select Purchase Date' });
            return;
        }
        if (!upitemid || !uprate || !upqty || !upbarcode) {
            Toast.fire({ icon: "error", title: ' Rate, Quantity, Barcode should not be empty' });
            return;
        }
        if (!uptotal_amount) {
            Toast.fire({ icon: "error", title: 'Please check the Total Amount' });
            return;
        }


        await setDoc(doc(db, 'purchases', purchaseid), {
            dealerId: dealerId,
            purchaseNo: purchaseNo,
            purchaseDate: purchaseDate,
            tcs: parseFloat(tcs),
            grandtotal: parseFloat(grandtotal),
            paidamount: parseFloat(paidamount),
            remaining: parseFloat(remaining),
            paytype: paytype,
            status: 'Active',
            uid: userData.uid,
            created: Timestamp.now()
        });
        await updateDoc(doc(db, 'purchasesitems', upitemid), {
            qty: parseFloat(upqty),
            rate: parseFloat(uprate),
            barcode: upbarcode,
            subtotal: parseFloat(upsubtotal),
            discount: parseFloat(updiscount),
            gst: parseFloat(upgst),
            gst_amount: parseFloat(upgst_amount),
            total_amount: parseFloat(uptotal_amount),
            created: Timestamp.now()
        });

        await addDoc(collection(db, 'barcodes'), {
            prodiid: itemid,
            barcode: upbarcode,
            uid: userData.uid,
            created: Timestamp.now()
        });

        fetchPurchaseItems(purchaseid);

        setitemid(""); setitem(""); setqty(1); setrate(""); setsubtotal(""); setdiscount(0); setgst(0);
        setgst_amount(""); settotal_amount(""); setbarcode("")


    }

    const deleteItems = async (purchaseid, docid) => {
        await deleteDoc(doc(db, "purchasesitems", docid));
        fetchPurchaseItems(purchaseid)


    }

    const updateItems = (id, barcode, rate, qty, discount, subtotal, gst, gst_amount, total_amount) => {
        setupbarcode(barcode);
        setupqty(qty);
        setuprate(rate);
        setupsubtotal(subtotal);
        setupdiscount(discount);
        setupgst(gst);
        setupgst_amount(gst_amount);
        setuptotal_amount(total_amount);
        setupitemid(id)
    }
    const storePurchase = async () => {

        if (!dealerId) {
            Toast.fire({ icon: "error", title: 'Please Select Dealer' });
            return;
        }
        if (!purchaseDate) {
            Toast.fire({ icon: "error", title: 'Please Select Purchase Date' });
            return;
        }


        if (grandtotal === "" || !paidamount === "") {
            Toast.fire({ icon: "error", title: 'Please check the grand total & paid ammount is correct1' });
            return;
        }

        try {
            // --------------------- update status of purchase table --------------------
            await updateDoc(doc(db, 'purchases', purchaseid), {
                status: 'Active',
                dealerId: dealerId,
                purchaseNo: purchaseNo,
                paiddate: purchaseDate,
                tcs: parseFloat(tcs),
                totdiscount: parseFloat(totdiscount),
                handlingcharge: parseFloat(handlingcharge),
                grandtotal: parseFloat(grandtotal),
                paidamount: parseFloat(paidamount),
                remaining: parseFloat(remaining),
                paytype: paytype,
            });

            // --------------------- update product stock --------------------
            const q11 = query(collection(db, 'purchasesitems'), where("purchaseid", "==", purchaseid));
            onSnapshot(q11, (querySnapshot3) => {
                querySnapshot3.docs.map(async (item) => {
                    const docRef = doc(db, 'products', item.data().itemid);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        let newstock;
                        if (isNaN(docSnap.data().godawanstock) || docSnap.data().godawanstock === "") {
                            newstock = parseFloat(item.data().qty);
                        } else {
                            newstock = parseFloat(docSnap.data().godawanstock) + parseFloat(item.data().qty);
                        }
                        await updateDoc(doc(db, 'products', item.data().itemid), {
                            godawanstock: newstock,
                        });
                        await updateDoc(doc(db, 'purchasesitems', item.id), {
                            status: "Active",
                        });
                    }
                }
                )
            });

            await setDoc(doc(db, 'dealeracchistory', delaeraccid), {
                dealerId: dealerId,
                purchasedocid: purchaseid,
                purchaseNo: purchaseNo,
                paiddate: purchaseDate,
                grandtotal: parseFloat(grandtotal),
                paidamount: parseFloat(paidamount),
                remaining: parseFloat(remaining),
                paytype: paytype,
                status: 'Active',
                note: 'Paid at the purchase',
                uid: userData.uid,
                created: Timestamp.now()
            });

            Toast.fire({ icon: "success", title: 'Purchase Entry Successfully Updated' });
            setpurchaseItesms([]); fetchPurchaseItems();
            setgrandtotal(0); setgrandtotal1(0); setpaidamount(0); setremaining(0); setdealerId(""); setpurchaseNo("");
            setpaytype("Cash");
            window.location.href = "/view-purchase";

        } catch (error) {
            Toast.fire({ icon: "error", title: 'Something was wrong please try again' });
            console.log(error.message)
        }
    }
    // ================================================================================================================================================
    // ================================================================================================================================================

    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary" style={{ float: "right" }} >|| Purchase Management ||</button>
                        <a className="btn btn-warning btn-sm" href="/view-purchase">View Purchase</a>&nbsp;
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#add-dealer">Add New Dealer</button>&nbsp;
                        <button className="btn btn-success btn-sm" data-toggle="modal" data-target="#add-product">Add New Product</button><br /><br />
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <form id="form" >
                                    <div className='row '>
                                        <div className='col-sm-4'>
                                            <label>Select Dealer Id  </label>
                                            <select className='form-control' autoFocus style={{ height: 35, fontSize: 14 }} value={dealerId} onChange={(e) => setdealerId(e.target.value)}>
                                                <option></option>
                                                {dealers.map((item) => (
                                                    <option value={item.id} key={item.id}>{item.data.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-sm-4">
                                            <label>Purchase No </label>
                                            <input type="text" className="form-control" style={{ height: 35, fontSize: 14 }} value={purchaseNo} onChange={(e) => setpurchaseNo(e.target.value)} />
                                        </div>
                                        <div className="col-sm-4">
                                            <label>Purchase Date </label>
                                            <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={purchaseDate} onChange={(e) => setpurchaseDate(e.target.value)} />
                                        </div>
                                    </div>
                                    <hr style={{
                                        color: 'orangered',
                                        backgroundColor: 'orangered',
                                        height: .2,
                                        borderColor: 'orangered'
                                    }} />
                                    <div className='row form-group ex1'>
                                        <table className='customers' style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th width="250"><label>Item</label></th>
                                                    <th><label>Barcode</label></th>
                                                    <th><label>Rate</label></th>
                                                    <th><label>Qty</label></th>
                                                    <th><label>Discount</label></th>
                                                    <th><label>Total </label></th>
                                                    <th><label>Gst  </label></th>
                                                    <th><label>Gst Amnt  </label></th>
                                                    <th><label>All Total</label></th>
                                                    <th width="80"><label>Action</label></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {/* <tr>
                                                    <td>
                                                        <Select options={products} value={item} onChange={handleSelectItem} />
                                                    </td>
                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={barcode} onChange={(e) => setbarcode(e.target.value)} placeholder='Barcode' />
                                                    </td>
                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={rate} onChange={(e) => setrate(e.target.value)} placeholder='Rate' />
                                                    </td>

                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={qty} onChange={(e) => setqty(e.target.value)} placeholder='Qty' />
                                                    </td>
                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={discount} onChange={(e) => setdiscount(e.target.value)} placeholder='Dis' />
                                                    </td>
                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={subtotal} onChange={(e) => setsubtotal(e.target.value)} readOnly />
                                                    </td>
                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={gst} onChange={(e) => setgst(e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={gst_amount} onChange={(e) => setgst_amount(e.target.value)} />
                                                    </td>
                                                    <td>
                                                        <input type="text" onKeyUp={() => caltotal()} className="form-control" style={{ height: 35, fontSize: 14 }} value={total_amount} onChange={(e) => settotal_amount(e.target.value)} readOnly />
                                                    </td>
                                                    <td>
                                                        &nbsp;<a title='Add Details' onClick={() => addPurchaseItems()} href='#0' className='btn btn-sm  btn-info'><i className='fa fa-plus'></i></a>
                                                    </td>
                                                </tr> */}
                                                {
                                                    purchaseItesms.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.data.itemname}</td>
                                                            <td>{item.data.barcode}</td>
                                                            <td>{item.data.rate}</td>
                                                            <td>{item.data.qty}</td>
                                                            <td>{item.data.discount}</td>
                                                            <td>{item.data.subtotal.toFixed(2)}</td>
                                                            <td>{item.data.gst}</td>
                                                            <td>{item.data.gst_amount.toFixed(2)}</td>
                                                            <td>{item.data.total_amount.toFixed(2)}</td>
                                                            <td>
                                                                &nbsp;<a title='Delete Entry' onClick={() => deleteItems(item.data.purchaseid, item.id)} href='#0' className='btn btn-xs  btn-danger'><i className='fa fa-trash'></i></a>
                                                                &nbsp;<a title='Update Entry' data-toggle="modal" data-target="#text-modal1" onClick={() => updateItems(item.id, item.data.barcode, item.data.rate, item.data.qty, item.data.discount,
                                                                    item.data.subtotal, item.data.gst, item.data.gst_amount, item.data.total_amount)} href='#0' className='btn btn-xs  btn-success'><i className='fa fa-edit'></i></a>
                                                            </td>
                                                        </tr>

                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                    <hr style={{
                                        color: 'orangered',
                                        backgroundColor: 'orangered',
                                        height: .2,
                                        borderColor: 'orangered'
                                    }} />
                                    <div className='row '>
                                        <div className='col-sm-6'>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>TCS 1% </label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={tcs} />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Handling Charges </label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} onKeyUp={() => finaltotal()} value={handlingcharge} onChange={(e) => sethandlingcharge(e.target.value)} />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Extra Discount </label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} onKeyUp={() => finaltotal()} value={totdiscount} onChange={(e) => settotdiscount(e.target.value)} />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Grand Total </label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} readOnly value={grandtotal} />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Paid</label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} onKeyUp={() => finaltotal()} value={paidamount} onChange={(e) => setpaidamount(e.target.value)} placeholder='Enter Paid Amount' />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Payment Type</label> </div>
                                                <div className="col-sm-7">
                                                    <select className='form-control' style={{ height: 35, fontSize: 14 }} value={paytype} onChange={(e) => setpaytype(e.target.value)}>
                                                        <option value="Cash">Cash</option>
                                                        <option value="Bank">Bank</option>
                                                        <option value="RTGS">RTGS</option>
                                                        <option value="Online Pay">Online Pay</option>
                                                    </select>
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                            <div className='row form-group'>
                                                <div className="col-sm-3"><label>Remaining</label> </div>
                                                <div className="col-sm-7">
                                                    <input type="text" className='form-control' style={{ height: 35, fontSize: 14 }} value={remaining} readOnly />
                                                </div>
                                                <div className='col-sm-2'></div>
                                            </div>
                                        </div>
                                    </div><hr />
                                    <center><button type='button' onClick={storePurchase} className='btn btn-info'>Update Purchase</button></center>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="add-product">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Product</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Product Category</label>
                                    <select value={prodcatid} onChange={(e) => setprodcatid(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} >
                                        <option value=""></option>
                                        {
                                            productCat.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.catname}</option>
                                            )
                                        }

                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>  Product Name</label>
                                    <input type="text" value={prodname} onChange={(e) => setprodname(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                                </div>
                            </div><br />
                            <div className='row'>
                                <div className="col-sm-6">
                                    <label>  Product Units</label>
                                    <select value={productunit} onChange={(e) => setproductunit(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} >
                                        <option value=""></option>
                                        {
                                            productUnitData.map((item, index) =>
                                                <option key={index} value={item.id}>{item.data.unit}</option>
                                            )
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-6">
                                    <label>  Stock</label>
                                    <input type="text" value={stock} onChange={(e) => setstock(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => productInsert()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="add-dealer">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Dealer</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Dealer</label>
                                    <input type="text" value={name} onChange={(e) => setname(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                                </div>
                                <div className="col-sm-6">
                                    <label>Mobile</label>
                                    <input type="text" value={mobile} onChange={(e) => setmobile(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                                </div>
                                <div className="col-sm-6 mt-2">
                                    <label>Address</label>
                                    <input type="text" value={address} onChange={(e) => setaddress(e.target.value)} className="form-control" style={{ height: 35, fontSize: 14 }} />
                                </div>
                            </div><br />
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => dealerInsert()} className="btn btn-primary" data-dismiss="modal">Save </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Purchase Item</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <table className='customers'>
                                    <thead>
                                        <tr>
                                            <th><label>Barcode</label></th>
                                            <th><label>Rate</label></th>
                                            <th><label>Qty</label></th>
                                            <th><label>Discount</label></th>
                                            <th><label>Total </label></th>
                                            <th><label>Gst  </label></th>
                                            <th><label>Gst Amnt  </label></th>
                                            <th><label>All Total</label></th>
                                            <th width="60"><label>Action</label></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={upbarcode} onChange={(e) => setupbarcode(e.target.value)} placeholder='Barcode' />
                                            </td>
                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={uprate} onChange={(e) => setuprate(e.target.value)} placeholder='Rate' />
                                            </td>

                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={upqty} onChange={(e) => setupqty(e.target.value)} placeholder='Qty' />
                                            </td>
                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={updiscount} onChange={(e) => setupdiscount(e.target.value)} placeholder='Dis' />
                                            </td>
                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={upsubtotal} onChange={(e) => setupsubtotal(e.target.value)} readOnly />
                                            </td>
                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={upgst} onChange={(e) => setupgst(e.target.value)} />
                                            </td>
                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={upgst_amount} onChange={(e) => setupgst_amount(e.target.value)} />
                                            </td>
                                            <td>
                                                <input type="text" onKeyUp={() => caltotal1()} className="form-control" style={{ height: 35, fontSize: 14 }} value={uptotal_amount} onChange={(e) => setuptotal_amount(e.target.value)} readOnly />
                                            </td>
                                            <td>
                                                &nbsp;<a title='Add Details' onClick={() => updatePurchaseItem()} href='#0' className='btn btn-sm  btn-info'><i className='fa fa-plus'></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
