import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import Login from './auth/Login';
import Register from './auth/Register';
import Header from './panel/Header';
import Menu from './panel/Menu';
import Footer from './panel/Footer';

import Dashboard from './components/Dashboard';
import ProductDetails from './components/ProductDetails';
import ProductCategory from './components/ProductCategory';
import ProductUnit from './components/ProductUnit';
import Customers from './components/Customers';
import CustomerAccount from './components/CustomerAccount';
import TableOrders from './components/TableOrders';
import DealerDetails from './components/DealerDetails';
import DealerAccount from './components/DealerAccount';
import AddPurchase from './components/AddPurchase';
import ViewPurchase from './components/ViewPurchase';
import CustomerOutstanding from './components/CustomerOutstanding';
import ExciseReport from './components/ExciseReport';
import SeatingCategory from './components/SeatingCategory';
import SeatingDetails from './components/SeatingDetails';
import SalePurchaseReport from './components/SalePurchaseReport';
import ViewOrders from './components/Vieworders';
import Staffdetails from './components/Staffdetails';
import Calendar from './components/Calendar';
import CustomerwiseReport from './components/CustomerwiseReport';
import Productwise from './components/Productwise';
import Daildenomination from './components/Daildenomination';
import Editpurchase from './components/Editpurchase';

import { auth } from './firebase';

function App() {
  const [user, setUser] = useState();
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUser(user);
      //  
    });
  });

  return (
    <div className="wrapper">
      {
        !user ?
          <Router>
            <Routes>
              <Route path='/' element={<Login />} />
              <Route path='/login' element={<Login />} />
              <Route path='/Register' element={<Register />} />
            </Routes>
          </Router>
          :
          <>
            <Menu />
            <Header />
            <Router>
              <Routes>
                <Route path='/' element={<Dashboard />} />
                <Route path='/home' element={<Dashboard />} />
                <Route path='/product-details' element={<ProductDetails />} />
                <Route path='/product-category' element={<ProductCategory />} />
                <Route path='/product-unit' element={<ProductUnit />} />
                <Route path='/seating-category' element={<SeatingCategory />} />
                <Route path='/seating-details' element={<SeatingDetails />} />
                <Route path='/customers' element={<Customers />} />
                <Route path='/customer-account' element={<CustomerAccount />} />
                <Route path='/table-orders' element={<TableOrders />} />
                <Route path='/dealer-details' element={<DealerDetails />} />
                <Route path='/dealer-account' element={<DealerAccount />} />
                <Route path='/add-purchase' element={<AddPurchase />} />
                <Route path='/view-purchase' element={<ViewPurchase />} />
                <Route path='/customer-outstanding' element={<CustomerOutstanding />} />
                <Route path='/excise-report' element={<ExciseReport />} />
                <Route path='/sale-purchase-report' element={<SalePurchaseReport />} />
                <Route path='/view-orders' element={<ViewOrders />} />
                <Route path='/staff-details' element={<Staffdetails />} />
                <Route path='/staff-attendence/:id' element={<Calendar />} />
                <Route path='/customerwise-report' element={<CustomerwiseReport />} />
                <Route path='/productwise-sale-report' element={<Productwise />} />
                <Route path='/daily-denomination' element={<Daildenomination />} />
                <Route path='/edit-purchase/:id' element={<Editpurchase />} />
              </Routes>
            </Router>
            <Footer />
          </>
      }

    </div>
  );
}

export default App;
