import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, query, orderBy, onSnapshot, getDoc, doc, where, } from 'firebase/firestore'
import moment from 'moment';
import Select from 'react-select'
import { useReactToPrint } from 'react-to-print';


export default function SalePurchaseReport() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setfromdate(formattedDate);
        settodate(formattedDate);
    }, []);

    const printRef = useRef();
    const [reportData, setreportData] = useState([]);
    const [type, settype] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [custtype, setcusttype] = useState("");
    const [totalamount, settotalamount] = useState("");
    const [paidamount, setpaidamount] = useState("");
    const [discount, setdiscount] = useState("");
    const [remaining, setremaining] = useState("");

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    })



    const getReport = () => {
        settotalamount(0);
        setpaidamount(0);
        setdiscount(0);
        setremaining(0);
        setreportData([]);

        if (type === "") {
            Toast.fire({ icon: "error", title: 'Please select report type' });
            return;
        }
        if (type === 'Sale') {
            setcusttype("Customer");
            const q1 = query(collection(db, 'orderitems'), orderBy("created", "desc"),
                where('orderdate', '>=', fromdate), where('orderdate', '<=', todate))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                let dealername = "";
                let totalamount = 0;
                let paidamount = 0;
                let discount = 0;
                let remaining = 0;
                for (const doc1 of querySnapshot1.docs) {
                    if (doc1.data().custid) {
                        const docRef = doc(db, "customers", doc1.data().custid);
                        const docSnap = await getDoc(docRef);
                        dealername = docSnap.data().name;
                    }
                    else {
                        dealername = "";
                    }
                    totalamount = parseFloat(totalamount) + parseFloat(doc1.data().total);
                    paidamount = parseFloat(paidamount) + parseFloat(doc1.data().paid);
                    discount = parseFloat(discount) + parseFloat(doc1.data().discount);
                    remaining = parseFloat(remaining) + parseFloat(doc1.data().remaining);

                    settotalamount(totalamount);
                    setpaidamount(paidamount);
                    setdiscount(discount);
                    setremaining(remaining);
                    let datetimeee = doc1.data().created;

                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        dealername: dealername,
                        datetime: datetimeee.toDate().toString()
                    });
                }
                setreportData(combinedData);
            });
        }
        else {
            setcusttype("Vendor");
            const q1 = query(collection(db, 'purchases'), orderBy("created", "desc"),
                where("status", "==", "Active"), where('purchaseDate', '>=', fromdate), where('purchaseDate', '<=', todate))
            onSnapshot(q1, async (querySnapshot1) => {
                const combinedData = [];
                let dealername = "";
                let totalamount = 0;
                let paidamount = 0;
                let discount = 0;
                let remaining = 0;
                for (const doc1 of querySnapshot1.docs) {
                    if (doc1.data().dealerId) {
                        const docRef = doc(db, "dealers", doc1.data().dealerId);
                        const docSnap = await getDoc(docRef);
                        dealername = docSnap.data().name;
                    }
                    else {
                        dealername = "";
                    }
                    totalamount = parseFloat(totalamount) + parseFloat(doc1.data().grandtotal);
                    paidamount = parseFloat(paidamount) + parseFloat(doc1.data().paidamount);
                    discount = parseFloat(discount) + parseFloat(0);
                    remaining = parseFloat(remaining) + parseFloat(doc1.data().remaining);

                    settotalamount(totalamount);
                    setpaidamount(paidamount);
                    setdiscount(discount);
                    setremaining(remaining);

                    combinedData.push({
                        id: doc1.id,
                        data: doc1.data(),
                        dealername: dealername,
                    });
                }
                setreportData(combinedData);
            });
        }
    }
    return (
        <div>
            <div className="content-wrapper">
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary" style={{ float: "right" }} >|| Sale Purchase Report  ||</button><br /><br />
                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" >
                                <div className='row '>
                                    <div className='col-sm-3'>
                                        <label>Report Type  </label>
                                        <select className='form-control' style={{ height: 35, fontSize: 14 }} value={type} onChange={(e) => settype(e.target.value)}>
                                            <option></option>
                                            <option value="Sale">Sale</option>
                                            <option value="Purchase">Purchase</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-3">
                                        <label>From Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                                    </div>
                                    <div className="col-sm-3">
                                        <label>To Date </label>
                                        <input type="date" className="form-control" style={{ height: 35, fontSize: 14 }} value={todate} onChange={(e) => settodate(e.target.value)} />
                                    </div>
                                    <div className='col-sm-3'>
                                        <br />
                                        <button onClick={() => getReport()} className='btn btn-info'>Get Report</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card card-primary card-outline">
                            <div className="card-body table-responsive p-3" ref={printRef}>
                                <center>
                                    <h6>Pratik Beer Shopee</h6>
                                    {/* <span>Ghatshil Road Road, Tuljapur, 413501</span><br /> */}
                                    {/* <span>Mobile No: 9921060146</span> */}
                                </center><hr />
                                <div className='row'>
                                    <div className='col-sm-4'>
                                        <span><b>From Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                    <div className='col-sm-4 text-center'>
                                        <h6 style={{ textTransform: "uppercase" }}>{type} Report</h6>
                                    </div>
                                    <div className='col-sm-4'>
                                        <span style={{ float: "right" }}><b>To Date : </b>{moment(fromdate).format('DD/MM/YYY')}</span>
                                    </div>
                                </div>
                                <div className='row '>
                                    <table className='table table-striped'>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Date</th>
                                            {
                                                type === 'Purchase' ?
                                                    <>
                                                        <th>{custtype} Name</th>
                                                        <th>Total Amount</th>
                                                        <th>Paid Amount</th>
                                                        <th>Reamaining</th>
                                                    </> :
                                                    <>
                                                        <>
                                                            <th>Prodcut Name</th>
                                                            <th>Rate</th>
                                                            <th>Quantity</th>
                                                            <th>Total</th>
                                                        </>
                                                    </>
                                            }

                                        </tr>
                                        {
                                            reportData.map((item, num = 1) =>
                                                <tr key={num + 1}>
                                                    <td width="80">{num + 1}</td>
                                                    {
                                                        type === 'Purchase' ?
                                                            <>
                                                                <td>{moment(item.data.purchaseDate).format('DD-MM-YYYY')}</td>
                                                                <td>{item.dealername}</td>
                                                                <td>{item.data.grandtotal}</td>
                                                                <td>{item.data.paidamount}</td>
                                                                <td>{item.data.remaining}</td>
                                                            </> :
                                                            <>
                                                                <td>{moment(item.datetime).format('DD-MM-YYYY H:m A')}</td>
                                                                <td>{item.data.itemname}</td>
                                                                <td>{item.data.rate}</td>
                                                                <td>{item.data.quantity}</td>
                                                                <td>{item.data.total}</td>
                                                            </>
                                                    }

                                                </tr>
                                            )}
                                        <tr>
                                            {
                                                type === 'Purchase' ?
                                                    <>
                                                        <td colSpan={3}><b>Total</b></td>
                                                        <td><b>{totalamount}</b></td>
                                                        <td><b>{paidamount}</b></td>
                                                        <td><b>{remaining}</b></td>
                                                    </>
                                                    :
                                                    <>
                                                        <td colSpan={4}><b>Total</b></td>
                                                        <td><b></b></td>
                                                        <td><b>{totalamount}</b></td>
                                                    </>
                                            }
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <center>
                                <button onClick={handlePrint} className='btn btn-info'>Print Report</button>
                            </center><br />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}
