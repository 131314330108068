import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDJp-9-QlzKCL8InwMPg4Oqss4xMn2BMrs",
  authDomain: "pratikbeershop-b0e46.firebaseapp.com",
  projectId: "pratikbeershop-b0e46",
  storageBucket: "pratikbeershop-b0e46.appspot.com",
  messagingSenderId: "987177241948",
  appId: "1:987177241948:web:941938d43bbae21b958599",
  measurementId: "G-S3WPLTEWZG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth  = getAuth();
export const db  = getFirestore(app);
export default app;