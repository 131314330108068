import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, getCountFromServer, updateDoc, writeBatch, getAggregateFromServer, sum } from 'firebase/firestore'
import Select from 'react-select'
import moment from 'moment';

function Dashboard() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });


  const [userData, setUserData] = useState();
  const [products, setproducts] = useState([]);
  const [orderdate, setorderdate] = useState("");

  const [reportData, setreportData] = useState([]);
  const [totalamount, settotalamount] = useState("");

  const [quantity, setquantity] = useState(1);
  const [barcode, setbarcode] = useState("");
  const [item, setitem] = useState("");

  const [updocid, setupdocid] = useState("");

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  useEffect(() => {
    fetchData1();
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    setorderdate(formattedDate);
  }, []);


  const fetchData1 = async () => {
    try {

      const q3 = query(collection(db, 'products'), orderBy("prodname", "desc"))
      onSnapshot(q3, async (querySnapshot1) => {

        const combinedData1 = [];
        let unit = "";
        for (const doc1 of querySnapshot1.docs) {

          combinedData1.push({
            id: doc1.id,
            data: doc1.data(),
            unit: unit,
            label: doc1.data().prodname + " " + unit,
            value: doc1.id,
          });
        }
        setproducts(combinedData1);
      });

      let fromdate = moment().format('YYYY-MM-DD');
      let todate = moment().format('YYYY-MM-DD');

      const q1 = query(collection(db, 'orderitems'), orderBy("created", "desc"),
        where('orderdate', '>=', fromdate), where('orderdate', '<=', todate))
      onSnapshot(q1, async (querySnapshot1) => {
        const combinedData = [];
        let dealername = "";
        let totalamount = 0;
        let paidamount = 0;
        let discount = 0;
        let remaining = 0;
        for (const doc1 of querySnapshot1.docs) {
          if (doc1.data().custid) {
            const docRef = doc(db, "customers", doc1.data().custid);
            const docSnap = await getDoc(docRef);
            dealername = docSnap.data().name;
          }
          else {
            dealername = "";
          }
          totalamount = parseFloat(totalamount) + parseFloat(doc1.data().total);
          paidamount = parseFloat(paidamount) + parseFloat(doc1.data().paid);
          discount = parseFloat(discount) + parseFloat(doc1.data().discount);
          remaining = parseFloat(remaining) + parseFloat(doc1.data().remaining);

          settotalamount(totalamount);
          let datetimeee = doc1.data().created;
          combinedData.push({
            id: doc1.id,
            data: doc1.data(),
            dealername: dealername,
            datetime: datetimeee.toDate().toString()
          });
        }
        setreportData(combinedData);
      });


    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }


  const handleSelectItem = (item) => {
    setitem("");
    addOrderItems(item.data.prodname + " " + item.unit, item.id, item.data.rate, quantity)
  };

  const handleSelectItem1 = async (item) => {
    if (!item) {
      setbarcode("");
      return;
    }
    setbarcode(item);

    // --------------------- update product stock --------------------
    const q11 = query(collection(db, 'products'), where("barcode", "==", item));
    const querySnapshot = await getDocs(q11);
    // console.log(querySnapshot.data());
    let itemid;
    let itemname;
    let rate;
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      itemid = doc.id;
      itemname = doc.data().prodname;
      rate = doc.data().rate;
    });
    addOrderItems(itemname, itemid, rate, quantity)
    setbarcode("");
    console.log("rohan");
  };



  const addOrderItems = async (item, itemid, rate, quantity) => {
    try {
      // Step 1: Add Order Item
      const orderDoc = await addDoc(collection(db, 'orderitems'), {
        itemname: item,
        itemid: itemid,
        orderdate: orderdate,
        quantity: parseFloat(quantity),
        rate: parseFloat(rate),
        total: parseFloat(quantity) * parseFloat(rate),
        uid: userData.uid,
        created: Timestamp.now(),
      });

      // Step 2: Get Total Quantity for the Item on Order Date
      const q2 = query(collection(db, 'orderitems'), where("itemid", "==", itemid), where('orderdate', '==', orderdate));
      const snapshot = await getAggregateFromServer(q2, { quantity: sum('quantity') });

      // Step 3: Update Product Stock
      const docProdRef = doc(db, 'products', itemid);
      const docSnap = await getDoc(docProdRef);

      if (docSnap.exists()) {
        let newstock = parseFloat(docSnap.data().stock) - quantity;
        await updateDoc(docProdRef, { stock: newstock });
      }

      // Step 4: Update or Add Product-Wise Sale
      const q3 = query(collection(db, 'productwisesale'), where("itemid", "==", itemid), where('orderdate', '==', orderdate));
      const saleSnapshot = await getDocs(q3);

      if (saleSnapshot.empty) {
        // Add new product-wise sale entry
        await addDoc(collection(db, 'productwisesale'), {
          itemname: item,
          itemid: itemid,
          orderdate: orderdate,
          rate: parseFloat(rate),
          quantity: snapshot.data().quantity,
          uid: userData.uid,
          created: Timestamp.now(),
        });
      } else {
        // Update existing product-wise sale entry
        const saleDocRef = saleSnapshot.docs[0].ref;
        await updateDoc(saleDocRef, { quantity: snapshot.data().quantity });
      }

      // Step 5: Display Success Toast and Update UI
      Toast.fire({ icon: "success", title: 'Sale Added' });
      fetchData1();  // Reload data after order is added
      setbarcode(""); // Reset barcode input field

    } catch (error) {
      console.log("Error adding order item:", error.message);
    }
  };


  const handleDelete = async (docid, quantity, itemid) => {

    const taskDocRef = doc(db, 'orderitems', docid)
    await deleteDoc(taskDocRef)
    Toast.fire({
      icon: "success",
      title: 'Entry Deleted Successfully!!'
    });


    const q2 = query(collection(db, 'orderitems'), where("itemid", "==", itemid), where('orderdate', '==', orderdate));
    const snapshot = await getAggregateFromServer(q2, {
      quantity: sum('quantity')
    });

    const q3 = query(collection(db, 'productwisesale'), where("itemid", "==", itemid), where('orderdate', '==', orderdate));
    onSnapshot(q3, async (querySnapshot1) => {
      if (querySnapshot1.docs.length === 0) {
        window.location.href = ""
        return;
      }
      else {
        await updateDoc(querySnapshot1.docs[0].ref, {
          quantity: snapshot.data().quantity,
        });
        window.location.href = ""
        return;
      }
    })


    fetchData1();
  }




  return (
    <div>
      <div className="content-wrapper">
        <section className="content">
          <button className="btn btn-secondary " style={{ float: "right", marginTop: 4 }} >|| Dashboard ||</button><br /><br />
        </section>
        <section className="content">
          <div className="container-fluid">
            <div className="card card-primary card-outline mt-1">
              <div className="card-body table-responsive p-3" >
                <div className='row'>
                  <div className='col-sm-4'>
                    <label>Quantity</label>
                    <input className='form-control' value={quantity} onChange={(e) => setquantity(e.target.value)} autoFocus />
                  </div>
                  <div className='col-sm-4'>
                    <label>Enter Barcode</label>
                    <input type='text' className='form-control' placeholder='Enter Barcode' value={barcode} onChange={(e) => handleSelectItem1(e.target.value)} />
                  </div>

                  <div className='col-sm-4'>
                    <label>Select Product</label>
                    <Select options={products} value={item} onChange={handleSelectItem} />
                  </div>
                </div><br />
                <div className='row '>
                  <table className='table table-striped'>
                    <tr>
                      <th>Sr No</th>
                      <th>Date</th>
                      <th>Prodcut Name</th>
                      <th>Rate</th>
                      <th>Quantity</th>
                      <th>Total</th>
                      <th>Action</th>
                    </tr>
                    <tr>
                      <td colSpan={4}><b>Total</b></td>
                      <td><b></b></td>
                      <td><b>{totalamount}</b></td>
                      <td><b></b></td>
                    </tr>
                    {
                      reportData.map((item, num = 1) =>
                        <tr key={num + 1}>
                          <td width="80">{num + 1}</td>
                          <td>{moment(item.datetime).format('DD-MM-YYYY H:mm:ss A')}</td>
                          {/* <td>{item.datetime}</td> */}
                          <td>{item.data.itemname}</td>
                          <td>{item.data.rate }</td>
                          <td>{item.data.quantity}</td>
                          <td>{item.data.total}</td>
                          <td>
                            {/* <a onClick={() => handleEdit(item.id, item.data.rate)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp; */}
                            <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id, item.data.quantity, item.data.itemid) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>
                          </td>
                        </tr>
                      )}

                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>


      </div>

    </div>
  )
}

export default Dashboard
