import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, getAggregateFromServer, sum, getCountFromServer, updateDoc } from 'firebase/firestore'
import moment from 'moment';

export default function Dailydenomination() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    const [userData, setUserData] = useState();
    const [dailytotalsid, setdailytotalsid] = useState("");
    const [oldDate, setoldDate] = useState("");
    const [oldTotal, setoldTotal] = useState(0);
    const [newTotal, setnewTotal] = useState(0);
    const [cur500, setcur500] = useState(0);
    const [cur200, setcur200] = useState(0);
    const [cur100, setcur100] = useState(0);
    const [cur50, setcur50] = useState(0);
    const [cur20, setcur20] = useState(0);
    const [cur10, setcur10] = useState(0);
    const [cur5, setcur5] = useState(0);
    const [cur2, setcur2] = useState(0);
    const [cur1, setcur1] = useState(0);
    const [onlineamount, setonlineamount] = useState(0);
    const [pigmiamount, setpigmiamount] = useState(0);
    const [expense, setexpense] = useState(0);
    const [handcash, sethandcash] = useState(0);

    const [tot500, settot500] = useState(0);
    const [tot200, settot200] = useState(0);
    const [tot100, settot100] = useState(0);
    const [tot50, settot50] = useState(0);
    const [tot20, settot20] = useState(0);
    const [tot10, settot10] = useState(0);
    const [tot5, settot5] = useState(0);
    const [tot2, settot2] = useState(0);
    const [tot1, settot1] = useState(0);

    const [dailytotals, setdailytotals] = useState([]);
    const [date, setdate] = useState("");
    const [descreption, setdescreption] = useState("");
    const [examount, setexamount] = useState("");

    useEffect(() => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setdate(formattedDate);
        fetchData1();
    }, []);



    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            setUserData(user);
        });
    });

    const fetchData1 = async () => {
        try {
            const q = query(collection(db, 'dailytotals'), orderBy("created", "desc"))
            onSnapshot(q, (querySnapshot) => {
                setdailytotals(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })));
            });


        } catch (error) {
            Toast.fire({
                icon: "error",
                title: error.message
            });
        }
    }



    const handleSubmit = async () => {
        if (!date) {
            Toast.fire({ icon: "error", title: 'Please select date' });
            return;
        }

        const q = query(collection(db, "dailytotals"), where("date", "==", date));
        const querySnapshot2 = await getDocs(q);
        if (!querySnapshot2.empty) {
            Toast.fire({ icon: "error", title: 'Daily Sale Total already generated of the date ' + date });
            return;
        }

        const q2 = query(collection(db, 'orderitems'), where('orderdate', '==', date));
        const snapshot = await getAggregateFromServer(q2, {
            total: sum('total')
        });

        await addDoc(collection(db, 'dailytotals'), {
            date: date,
            total: snapshot.data().total,
            cur500: parseFloat(cur500),
            cur200: parseFloat(cur200),
            cur100: parseFloat(cur100),
            cur50: parseFloat(cur50),
            cur20: parseFloat(cur20),
            cur10: parseFloat(cur10),
            cur5: parseFloat(cur5),
            cur2: parseFloat(cur2),
            cur1: parseFloat(cur1),
            onlineamount: onlineamount,
            pigmiamount: pigmiamount,
            expense: expense,
            status: "Active",
            uid: userData.uid,
            created: Timestamp.now()
        });


        setdate("");
        Toast.fire({
            icon: "success",
            title: ' Daily Total Sale Successfully Generated!!'
        });

        fetchData1()
    }

    const handleDelete = async (docid) => {
        const taskDocRef = doc(db, 'dailytotals', docid)
        await deleteDoc(taskDocRef)

        // const q12 = query(collection(db, 'dailyexpenses'), where("dailytotalsid", "==", docid));
        // onSnapshot(q12, (querySnapshot2) => {
        //     querySnapshot2.docs.map(async (item) => {
        //         const taskDocRef2 = doc(db, 'dailyexpenses', item.id)
        //         await deleteDoc(taskDocRef2)
        //     }
        //     )
        // });

        Toast.fire({
            icon: "success",
            title: 'Daily  Sale Total Deleted Successfully!!'
        });
        fetchData1();
    }

    // const handleDelete1 = async (docid) => {
    //     const taskDocRef2 = doc(db, 'dailyexpenses', docid)
    //     await deleteDoc(taskDocRef2)

    //     Toast.fire({
    //         icon: "success",
    //         title: 'Daily Expense Deleted Successfully!!'
    //     });
    //     fetchData1();
    // }

    // const addExpense = (docid, rdate) => {
    //     setdailytotalsid(docid)
    //     setdate(rdate);
    // }

    // const viewExpense = async (docid, total, date) => {
    //     const q = query(collection(db, 'dailyexpenses'), where("dailytotalsid", "==", docid));
    //     onSnapshot(q, (querySnapshot2) => {
    //         setdailyexpense(querySnapshot2.docs.map(doc => ({
    //             id: doc.id,
    //             data: doc.data(),
    //         })));
    //     });

    //     const g3 = query(collection(db, 'dailyexpenses'), where("dailytotalsid", "==", docid));
    //     const snapshot2 = await getAggregateFromServer(g3, {
    //         examount: sum('examount')
    //     });

    //     setoldTotal(total)
    //     setdate(date)
    //     setdailyexamount(snapshot2.data().examount)
    //     setdailyexamount(snapshot2.data().examount)
    // }


    const addDenomin = async (docid, rdate, total, cur500, cur200, cur100, cur50, cur20, cur10, cur5, cur2, cur1, onlineamount, pigmiamount, expense) => {
        setoldDate(rdate)
        setoldTotal(total)
        setdailytotalsid(docid)
        setcur500(cur500);
        setcur200(cur200);
        setcur100(cur100);
        setcur50(cur50);
        setcur20(cur20);
        setcur10(cur10);
        setcur5(cur5);
        setcur2(cur2);
        setcur1(cur1);
        setonlineamount(onlineamount);
        setpigmiamount(pigmiamount);
        setexpense(expense);
        caltotal()

        let tot500 = parseFloat(cur500) * 500;
        let tot200 = parseFloat(cur200) * 200;
        let tot100 = parseFloat(cur100) * 100;
        let tot50 = parseFloat(cur50) * 50;
        let tot20 = parseFloat(cur20) * 20;
        let tot10 = parseFloat(cur10) * 10;
        let tot5 = parseFloat(cur5) * 5;
        let tot2 = parseFloat(cur2) * 2;
        let tot1 = parseFloat(cur1) * 1;

        settot500(tot500);
        settot200(tot200);
        settot100(tot100);
        settot50(tot50);
        settot20(tot20);
        settot10(tot10);
        settot5(tot5);
        settot2(tot2);
        settot1(tot1);

        let rtotal = parseFloat(tot500) + parseFloat(tot200) + parseFloat(tot100) + parseFloat(tot50) + parseFloat(tot20) + parseFloat(tot10) +
            parseFloat(tot5) + parseFloat(tot2) + parseFloat(tot1);

        let rem = parseFloat(total) - (parseFloat(onlineamount) + parseFloat(pigmiamount) + parseFloat(expense));

        sethandcash(rem);
        setnewTotal(rtotal);
    }

    const caltotal = () => {
        let tot500 = parseFloat(cur500) * 500;
        let tot200 = parseFloat(cur200) * 200;
        let tot100 = parseFloat(cur100) * 100;
        let tot50 = parseFloat(cur50) * 50;
        let tot20 = parseFloat(cur20) * 20;
        let tot10 = parseFloat(cur10) * 10;
        let tot5 = parseFloat(cur5) * 5;
        let tot2 = parseFloat(cur2) * 2;
        let tot1 = parseFloat(cur1) * 1;

        settot500(tot500);
        settot200(tot200);
        settot100(tot100);
        settot50(tot50);
        settot20(tot20);
        settot10(tot10);
        settot5(tot5);
        settot2(tot2);
        settot1(tot1);

        let rtotal = parseFloat(tot500) + parseFloat(tot200) + parseFloat(tot100) + parseFloat(tot50) + parseFloat(tot20) + parseFloat(tot10) +
            parseFloat(tot5) + parseFloat(tot2) + parseFloat(tot1);

        let rem = parseFloat(oldTotal) - (parseFloat(onlineamount) + parseFloat(pigmiamount) + parseFloat(expense));

        sethandcash(rem);
        setnewTotal(rtotal);
    }

    const submitDenom = async () => {
        await updateDoc(doc(db, 'dailytotals', dailytotalsid), {
            cur500: parseFloat(cur500),
            cur200: parseFloat(cur200),
            cur100: parseFloat(cur100),
            cur50: parseFloat(cur50),
            cur20: parseFloat(cur20),
            cur10: parseFloat(cur10),
            cur5: parseFloat(cur5),
            cur2: parseFloat(cur2),
            cur1: parseFloat(cur1),
            onlineamount: onlineamount,
            pigmiamount: pigmiamount,
            expense: expense,
        });

        Toast.fire({
            icon: "success",
            title: 'Denomination added successfully!!'
        });

    }

    const handleExpense = async () => {
        if (!descreption || !examount) {
            Toast.fire({
                icon: "error",
                title: 'Descreption or Amount must not be Empty'
            });
        }

        await addDoc(collection(db, 'dailyexpenses'), {
            date: date,
            dailytotalsid: dailytotalsid,
            descreption: descreption,
            examount: parseFloat(examount),
            uid: userData.uid,
            created: Timestamp.now()
        });

        setdescreption(""); setexamount("");

        Toast.fire({
            icon: "success",
            title: 'Daily Expense added successfully!!'
        });

    }


    return (
        <div>
            <div className="content-wrapper">
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <button className="btn btn-secondary " style={{ float: "right" }} >|| Daily  Sale Total ||</button>
                        <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Generate Daily Sale Total</button><br /><br />
                        <div className="card card-primary card-outline ex1">
                            <div className="card-body table-responsive p-0" >
                                <table className="table table-bordered table-hover table-striped table-head-fixed">
                                    <thead>
                                        <tr>
                                            <th>Sr No</th>
                                            <th>Date</th>
                                            <th>Total Amount</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            dailytotals.map((item, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td> {moment(item.data.date).format('DD-MM-YYYY')}</td>
                                                    <td>{item.data.total}</td>
                                                    <td>
                                                        <a onClick={() => addDenomin(item.id, item.data.date, item.data.total, item.data.cur500, item.data.cur200, item.data.cur100, item.data.cur50, item.data.cur20, item.data.cur10, item.data.cur5, item.data.cur2, item.data.cur1, item.data.onlineamount, item.data.pigmiamount, item.data.expense)}
                                                            className="btn btn-sm btn-success" href='#0' data-toggle="modal" data-target="#text-modal1" > <i className="fa fa-money"></i></a>&nbsp;
                                                        <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                        {/* <a onClick={() => addExpense(item.id, item.data.date)} className="btn btn-sm btn-info" href='#0' data-toggle="modal" data-target="#text-modal2"> <i className="fa fa-plus"></i></a>&nbsp; */}
                                                        {/* <a onClick={() => viewExpense(item.id, item.data.total, item.data.date)} className="btn btn-sm btn-warning" href='#0' data-toggle="modal" data-target="#text-modal3"> <i className="fa fa-eye"></i></a> */}
                                                    </td>
                                                </tr>)
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* ============================================= modal start ================================================= */}

            <div className="modal fade" id="insert-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Generate  Report</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Date </label>
                                    <input type="date" value={date} onChange={(e) => setdate(e.target.value)} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Generate Report </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="text-modal1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Denomination</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <table className='customers2'>
                                <tr>
                                    <td colSpan={4}><label style={{ color: "red", fontSize: 20 }}>Total Collection</label></td>
                                    <td><label style={{ color: "red", fontSize: 20 }}>{oldTotal}</label></td>
                                </tr>
                                <tr>
                                    <td><label>Online Amount</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={onlineamount} onKeyUp={caltotal} onChange={(e) => setonlineamount(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{onlineamount}</label></td>
                                </tr>
                                <tr>
                                    <td><label>Pigmi Amount</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={pigmiamount} onKeyUp={caltotal} onChange={(e) => setpigmiamount(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{pigmiamount}</label></td>
                                </tr>
                                <tr>
                                    <td><label>Expense</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={expense} onKeyUp={caltotal} onChange={(e) => setexpense(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{expense}</label></td>
                                </tr>
                                <tr>
                                    <td colSpan={4}><label style={{ color: "green", fontSize: 20 }}>Cash In Hand</label></td>
                                    <td><label style={{ color: "green", fontSize: 20 }}>{handcash}</label></td>
                                </tr>
                                <tr>
                                    <td><label>500</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur500} onKeyUp={caltotal} onChange={(e) => setcur500(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot500}</label></td>
                                </tr>
                                <tr>
                                    <td><label>200</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur200} onKeyUp={caltotal} onChange={(e) => setcur200(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot200}</label></td>
                                </tr>
                                <tr>
                                    <td><label>100</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur100} onKeyUp={caltotal} onChange={(e) => setcur100(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot100}</label></td>
                                </tr>
                                <tr>
                                    <td><label>50</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur50} onKeyUp={caltotal} onChange={(e) => setcur50(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot50}</label></td>
                                </tr>
                                <tr>
                                    <td><label>20</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur20} onKeyUp={caltotal} onChange={(e) => setcur20(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot20}</label></td>
                                </tr>
                                <tr>
                                    <td><label>10</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur10} onKeyUp={caltotal} onChange={(e) => setcur10(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot10}</label></td>
                                </tr>
                                <tr>
                                    <td><label>5</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur5} onKeyUp={caltotal} onChange={(e) => setcur5(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot5}</label></td>
                                </tr>
                                <tr>
                                    <td><label>2</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur2} onKeyUp={caltotal} onChange={(e) => setcur2(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot2}</label></td>
                                </tr>
                                <tr>
                                    <td><label>1</label></td>
                                    <td><label>X</label></td>
                                    <td><input type='text' value={cur1} onKeyUp={caltotal} onChange={(e) => setcur1(e.target.value)} style={{ width: 100 }} /></td>
                                    <td><label>=</label></td>
                                    <td><label>{tot1}</label></td>
                                </tr>
                                <tr>
                                    <td colSpan={4}><label style={{ color: "green", fontSize: 20 }}>Denomination Total</label></td>
                                    <td><label style={{ color: "green", fontSize: 20 }}>{newTotal}</label></td>
                                </tr>

                            </table>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" onClick={() => submitDenom()} className="btn btn-success" >Submit</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="modal fade" id="text-modal2">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Expense</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>  Descreption </label>
                                    <input type="text" value={descreption} onChange={(e) => setdescreption(e.target.value)} className="form-control" />
                                </div>
                                <div className="col-sm-6">
                                    <label>  Amount </label>
                                    <input type="text" value={examount} onChange={(e) => setexamount(e.target.value)} className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="submit" onClick={() => handleExpense()} className="btn btn-primary" data-dismiss="modal">Add </button>
                            <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className="modal fade" id="text-modal3">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">View Expenses</h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <table className='customers' style={{ width: "100%" }}>
                                <thead>
                                    <tr>
                                        <th><label>Descreption</label></th>
                                        <th><label>Amount</label></th>
                                        <th><label>Action</label></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        dailyexpense.map((item, index) =>
                                            <tr key={index}>
                                                <td>{item.data.descreption}</td>
                                                <td>{item.data.examount}</td>
                                                <td>
                                                    <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete1(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                                                </td>
                                            </tr>

                                        )}
                                </tbody>
                            </table>
                            <h6>Date : {moment(date).format('DD-MM-YYYY')}</h6>
                            <h6>Sale Amount : {oldTotal} /-</h6>
                            <h6>Expense Amount : {dailyexamount} /-</h6>
                            <h6>Profit Amount : {oldTotal - dailyexamount} /-</h6>
                        </div>

                    </div>
                </div>
            </div> */}


        </div>
    )
}
