import React, { useEffect, useState, useRef } from 'react'
import '.././App.css';
import Swal from 'sweetalert2';
import { auth, db } from '../firebase';
import { collection, addDoc, Timestamp, query, orderBy, onSnapshot, getDoc, getDocs, deleteDoc, doc, setDoc, where, limit, updateDoc } from 'firebase/firestore'
import moment from 'moment';

export default function ProductDetails() {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  const [userData, setUserData] = useState();
  const [productCat, setproductCat] = useState([]);
  const [productUnitData, setproductUnitData] = useState([]);
  const [stocks, setstocks] = useState([]);
  const [searchText, setsearchText] = useState("");
  const [productunit, setproductunit] = useState("");
  const [stock, setstock] = useState("");
  const [godawanstock, setgodawanstock] = useState("");
  const [products, setproducts] = useState([]);
  const [prodcatid, setprodcatid] = useState("");
  const [prodname, setprodname] = useState("");
  const [rate, setrate] = useState("");
  const [barcode, setbarcode] = useState("");
  const [excise, setexcise] = useState("YES");

  const [updocid, setupdocid] = useState("");
  const [upprodcatid, setupprodcatid] = useState("");
  const [upprodname, setupprodname] = useState("");
  const [upprodstock, setupprodstock] = useState("");
  const [upgodawanstock, setupgodawanstock] = useState("");
  const [upprodunit, setupprodunit] = useState("");
  const [upprodrate, setupprodrate] = useState("");
  const [upexcise, setupexcise] = useState("");
  const [upbarcode, setupbarcode] = useState("");

  const [adocid, setadocid] = useState("");
  const [astock, setastock] = useState("");
  const [agodawan, setagodawan] = useState("");

  useEffect(() => {
    if (searchText === '') {
      fetchData1()
    } else {
      fetchData2()
    }
  }, [searchText]);



  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      setUserData(user);
    });
  });

  const fetchData1 = async () => {
    try {
      const q = query(collection(db, 'productcats'))
      onSnapshot(q, (querySnapshot) => {
        setproductCat(querySnapshot.docs.map(doc => ({
          id: doc.id,
          data: doc.data()
        })));
      });

      const q1 = query(collection(db, 'products'), orderBy("prodname", "asc"))
      onSnapshot(q1, async (querySnapshot1) => {

        const combinedData = [];
        let catname = "";
        let unit = "";
        for (const doc1 of querySnapshot1.docs) {
          combinedData.push({
            id: doc1.id,
            data: doc1.data(),
            catname: catname,
            unit: unit
          });
        }
        setproducts(combinedData);
      });

      const q2 = query(collection(db, 'productunits'), orderBy("created", "asc"))
      onSnapshot(q2, (querySnapshot2) => {
        setproductUnitData(querySnapshot2.docs.map(doc => ({
          id: doc.id,
          data: doc.data(),
          isChecked: true
        })));
      });

    } catch (error) {
      Toast.fire({
        icon: "error",
        title: error.message
      });
    }
  }

  const fetchData2 = async () => {
    try {
      if (productCat) {
        const filteredData = products.filter((item) =>
          item.data.prodname.toUpperCase().includes(searchText.toUpperCase())
        );
        setproducts(filteredData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  const handleSubmit = async () => {
    if (!prodname || !prodcatid || !rate) {
      Toast.fire({
        icon: "error",
        title: 'Please fill all the fields!!'
      });
      return;
    }

    const docref = await addDoc(collection(db, 'products'), {
      prodcatid: prodcatid,
      prodname: prodname,
      unit: productunit,
      rate: rate,
      stock: stock,
      godawanstock: godawanstock,
      excise: excise,
      uid: userData.uid,
      created: Timestamp.now()
    });

    await addDoc(collection(db, 'barcodes'), {
      prodiid: docref.id,
      barcode: barcode,
      uid: userData.uid,
      created: Timestamp.now()
    });


    setprodcatid("");
    setprodname("");
    Toast.fire({
      icon: "success",
      title: 'Product Added Successfully!!'
    });
  }

  const handleDelete = async (docid) => {
    const taskDocRef = doc(db, 'products', docid)
    await deleteDoc(taskDocRef)
    Toast.fire({
      icon: "success",
      title: 'Product Deleted Successfully!!'
    });
    fetchData1();
  }

  const handleEdit = (docid, category, prodname, unit, stock, rate, excise, godawan, barcode1) => {
    setupbarcode("")
    setupbarcode(barcode1)
    setupprodrate("");
    setupdocid(docid);
    setupprodname(prodname);
    setupprodunit(unit);
    setupprodcatid(category);
    setupprodstock(stock);
    setupprodrate(rate);
    setupexcise(excise)
    setupgodawanstock(godawan)
  }

  const addstock = (id, stock, godawanstock) => {
    setagodawan(godawanstock);
    setastock(godawanstock);
    setadocid(id);
  }

  const handleUpdate = async () => {
    if (!upprodname || !upprodrate) {
      Toast.fire({
        icon: "error",
        title: 'Please fill all the fields!!'
      });
      return;
    }

    try {
      if (upprodunit) {
        await setDoc(doc(db, "products", updocid), {
          prodcatid: upprodcatid,
          prodname: upprodname,
          unit: upprodunit,
          stock: parseFloat(upprodstock),
          godawanstock: parseFloat(upgodawanstock),
          rate: upprodrate,
          barcode: upbarcode || null,
          excise: upexcise,
          uid: userData.uid,
          created: Timestamp.now()
        });
      } else {
        await setDoc(doc(db, "products", updocid), {
          prodcatid: upprodcatid,
          prodname: upprodname,
          unit: "",
          stock: parseFloat(upprodstock),
          godawanstock: parseFloat(upgodawanstock),
          rate: upprodrate,
          barcode: upbarcode || null,
          excise: upexcise,
          uid: userData.uid,
          created: Timestamp.now()
        });
      }


      Toast.fire({
        icon: "success",
        title: 'Product Updated Successfully!!'
      });

      fetchData1();
    } catch (error) {
      console.log(error.message);
    }

  }

  const handleUpdate1 = async () => {
    if (!astock) {
      Toast.fire({
        icon: "error",
        title: 'Stock should not be empty!!'
      });
      return;
    }

    if (!agodawan) {
      Toast.fire({
        icon: "error",
        title: 'Godawan Stock should not be empty!!'
      });
      return;
    }
    let r1stock = parseInt(astock);
    let r2stock = parseInt(agodawan);

    if (r1stock > r2stock) {
      Toast.fire({
        icon: "error",
        title: 'Stock should not be greater than godawan stock!!'
      });
      return;
    }

    try {
      const docRef = doc(db, 'products', adocid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let godawanstock = parseFloat(docSnap.data().godawanstock) - parseFloat(astock);

        let newstock;
        if (isNaN(docSnap.data().stock) || docSnap.data().stock === "") {
          newstock = parseFloat(astock);
        } else {
          newstock = parseFloat(docSnap.data().stock) + parseFloat(astock);
        }

        await updateDoc(doc(db, 'products', adocid), {
          godawanstock: godawanstock,
          stock: newstock,
        });


        await addDoc(collection(db, 'stockentry'), {
          godawanstock: godawanstock,
          stock: newstock,
          adocid: adocid,
          created: moment().format('DD-MM-YYYY H:mm a')
        });
      }

      Toast.fire({
        icon: "success",
        title: 'Product Updated Successfully!!'
      });

      fetchData1();
    } catch (error) {
      console.log(error.message);
    }

  }

  const handleView = (docid) => {
    const q = query(collection(db, 'stockentry'), where("adocid", "==", docid), orderBy('created', 'asc'));
    onSnapshot(q, (querySnapshot2) => {
      setstocks(querySnapshot2.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })));
    });
  }


  return (
    <div>
      <div className="content-wrapper">
        <br />
        <section className="content">
          <div className="container-fluid">
            <button className="btn btn-secondary " style={{ float: "right" }} >|| Product Details ||</button>
            <button className="btn btn-info btn-sm" data-toggle="modal" data-target="#insert-modal">Add Product</button><br /><br />
            <div className="card card-primary card-outline ex1">
              <div className=" p-1">
                &nbsp;&nbsp;<input type="search" value={searchText} onChange={(e) => setsearchText(e.target.value)}
                  className='form-control col-sm-4' placeholder='Type here......' />&nbsp;
              </div>
              <div className="card-body table-responsive p-0" >
                <table className="table table-bordered table-hover table-striped table-head-fixed">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Product Name</th>
                      <th>Barcode</th>
                      <th>Product Rate</th>
                      <th>Stock</th>
                      <th>Godawan Stock</th>
                      <th>Excise Product</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      products.map((item, num = 1) =>
                        <tr key={num + 1}>
                          <td width="80">{num + 1}</td>
                          <td>{item.data.prodname} {item.unit}</td>
                          <td>{item.data.barcode}</td>
                          <td>{item.data.rate} </td>
                          <td>
                            {
                              item.data.stock >= 0 &&
                              <>{item.data.stock}</>
                            }
                          </td>
                          <td>
                            {
                              item.data.godawanstock >= 0 &&
                              <>{item.data.godawanstock}</>
                            }
                          </td>
                          <td>{item.data.excise} </td>
                          <td>
                            <a onClick={() => handleEdit(item.id, item.data.prodcatid, item.data.prodname, item.data.unit, item.data.stock, item.data.rate, item.data.excise, item.data.godawanstock, item.data.barcode)} href='#0' data-toggle="modal" data-target="#text-modal1" className="btn btn-sm btn-success" > <i className="fa fa-edit"></i></a>&nbsp;
                            <a onClick={() => { if (window.confirm('Are u sure?')) { handleDelete(item.id) }; }} className="btn btn-sm btn-danger" href='#0' > <i className="fa fa-trash"></i></a>&nbsp;
                            <a onClick={() => addstock(item.id, item.data.stock, item.data.godawanstock)} href='#0' data-toggle="modal" data-target="#text-modal2" className="btn btn-sm btn-info" > <i className="fa fa-plus"></i> Transfer Stock</a>&nbsp;
                            <a onClick={() => handleView(item.id)} href='#0' data-toggle="modal" data-target="#text-modal3" className="btn btn-sm btn-primary" > <i className="fa fa-eye"></i> View Stock</a>&nbsp;
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* ============================================= modal start ================================================= */}

      <div className="modal fade" id="insert-modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Add Product</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>Product Category</label>
                  <select value={prodcatid} onChange={(e) => setprodcatid(e.target.value)} className="form-control" >
                    <option value=""></option>
                    {
                      productCat.map((item, index) =>
                        <option key={index} value={item.id}>{item.data.catname}</option>
                      )
                    }

                  </select>
                </div>
                <div className="col-sm-6">
                  <label>  Product Name</label>
                  <input type="text" value={prodname} onChange={(e) => setprodname(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Product Units</label>
                  <select value={productunit} onChange={(e) => setproductunit(e.target.value)} className="form-control" >
                    <option value=""></option>
                    {
                      productUnitData.map((item, index) =>
                        <option key={index} value={item.id}>{item.data.unit}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Rate</label>
                  <input type="text" value={rate} onChange={(e) => setrate(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Stock</label>
                  <input type="text" value={stock} onChange={(e) => setstock(e.target.value)} className="form-control" readOnly/>
                </div>
                <div className="col-sm-6 mt-2">
                  <label> Godawan Stock</label>
                  <input type="text" value={godawanstock} onChange={(e) => setgodawanstock(e.target.value)} className="form-control" readOnly />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Excise Product</label>
                  <select value={excise} onChange={(e) => setexcise(e.target.value)} className="form-control" >
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
                <div className="col-sm-6 mt-2">
                  <label> Barcode</label>
                  <input type="text" value={barcode} onChange={(e) => setbarcode(e.target.value)} className="form-control" />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="submit" onClick={() => handleSubmit()} className="btn btn-primary" data-dismiss="modal">Save </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="text-modal1">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Edit Product</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-6">
                  <label>Product Category</label>
                  <select value={upprodcatid} onChange={(e) => setupprodcatid(e.target.value)} className="form-control" >
                    <option value=""></option>
                    {
                      productCat.map((item, index) =>
                        <option key={index} value={item.id}>{item.data.catname}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-sm-6">
                  <label> Product Name</label>
                  <input type="text" value={upprodname} onChange={(e) => setupprodname(e.target.value)} className="form-control" placeholder="Enter  Item Name ..." />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Product Units</label>
                  <select value={upprodunit} onChange={(e) => setupprodunit(e.target.value)} className="form-control" >
                    <option value=""></option>
                    {
                      productUnitData.map((item, index) =>
                        <option key={index} value={item.id}>{item.data.unit}</option>
                      )
                    }
                  </select>
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Rate</label>
                  <input type="text" value={upprodrate} onChange={(e) => setupprodrate(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Stock</label>
                  <input type="text" value={upprodstock} onChange={(e) => setupprodstock(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label> Godawan Stock</label>
                  <input type="text" value={upgodawanstock} onChange={(e) => setupgodawanstock(e.target.value)} className="form-control" />
                </div>
                <div className="col-sm-6 mt-2">
                  <label>  Excise Product</label>
                  <select value={upexcise} onChange={(e) => setupexcise(e.target.value)} className="form-control" >
                    <option></option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </div>
                <div className="col-sm-6 mt-2">
                  <label> Barcode</label>
                  <input type="text" value={upbarcode} onChange={(e) => setupbarcode(e.target.value)} className="form-control" />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdate()} className="btn btn-primary" data-dismiss="modal">Update </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="text-modal2">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Transfer Stock Godawan to Shop</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-3">
                  <label>  Stock</label>
                  <input type="text" value={astock} onChange={(e) => setastock(e.target.value)} className="form-control" />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" onClick={() => handleUpdate1()} className="btn btn-primary" data-dismiss="modal">Update </button>
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="text-modal3">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">View Stock Entries</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span></button>
            </div>
            <div className="modal-body">
              <table className='customers' style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th><label>Godawan Stock  </label></th>
                    <th><label>Stock  </label></th>
                    <th><label>Date Time</label></th>
                  </tr>
                </thead>
                <tbody>

                  {
                    stocks.map((item, index) =>
                      <tr key={index}>
                        <td>{item.data.godawanstock}</td>
                        <td>{item.data.stock}</td>
                        <td>{item.data.created}</td>
                      </tr>

                    )}
                </tbody>
              </table>
            </div>
            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
